<template>
    <a :href="getMicrosoftUrl('/')"
        class="text-wobby-black-800 hover:text-wobby-black-900 transition-all duration-200 flex ring-1 shadow-sm border-0 font-fakt-medium bg-white w-full items-center justify-center gap-3 rounded-md hover:bg-gray-100 py-2.5 ring-gray-300">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" width="15" height="16">
            <path fill="#f3f3f3" d="M0 0h23v23H0z" />
            <path fill="#f35325" d="M1 1h10v10H1z" />
            <path fill="#81bc06" d="M12 1h10v10H12z" />
            <path fill="#05a6f0" d="M1 12h10v10H1z" />
            <path fill="#ffba08" d="M12 12h10v10H12z" />
        </svg>
        <span class="text-lg">{{ props.buttonText }}</span>

    </a>
</template>

<script lang="ts" setup>

const props = defineProps({
    buttonText: {
        type: String,
        default: 'Sign up with Microsoft',
    },
});

const getMicrosoftUrl = (from: string) => {
    const rootUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';

    const options: Record<string, string> = {
        redirect_uri: `${window.location.origin}/api/identity/oauth/microsoft`,
        client_id: '9036afd3-3841-43b3-bc78-80215a7fbe40',
        response_type: 'code',
        scope: 'openid profile email',
    }

    const qs = new URLSearchParams(options);

    return `${rootUrl}?${qs.toString()}`;
};
</script>